import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';

import { APP_CONFIG, WEBSERVICE } from '../commom/constants';
import { LoginProvider } from '../enums/login-provider.enum';
import { HttpHelper } from '../helpers/http.helper';
import { UtilHelper } from '../helpers/utils.helper';
import { ErrorApp } from '../models/error.model';
import { AnalyticsService } from './analytics.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class RequestService {

	private header: any;
	private token: string;
	private userService: UserService;

	private httpHelper: HttpHelper;

	constructor(
		private analyticsService: AnalyticsService,
		private httpClient: HttpClient,
		private httpNative: HTTP,
		private network: Network,
		private platform: Platform
	) {
		this.platform.ready().then(() => {
			this.httpHelper = new HttpHelper(this.httpClient, this.httpNative);
		});
	}

	//Para contornar erros de circular dependency
	setUserService(service: UserService): void {
		this.userService = service;
	}

	setAuthToken(token: string): void {
		this.token = token;
	}

	getAuthToken(): string {
		return this.token;
	}

	getData(url: string, forceUseHttpClient?: boolean): Promise<any> {
		return new Promise((resolve, reject) => {
			this.checkConnection().then(() => {
				const getRequest: VoidFunction = () => {
					this.httpHelper.get(url, this.getRequestOptions(url), forceUseHttpClient).then((resp) => {
						this.header = null;
						resolve(this.getJsonData(resp));
					}, (error) => {
						this.catchError(error, url, getRequest, reject);
					}).catch((error) => {
						this.catchError(error, url, getRequest, reject);
					});
				};
				getRequest();
			}, (error) => {
				reject(error);
			});
		});
	}

	putData(url: string, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.checkConnection().then(() => {
				const putRequest: VoidFunction = () => {
					this.httpHelper.put(url, data, this.getRequestOptions(url)).then((resp) => {
						this.header = null;
						resolve(this.getJsonData(resp));
					}, (error) => {
						this.catchError(error, url, putRequest, reject, data);
					}).catch((error) => {
						this.catchError(error, url, putRequest, reject, data);
					});
				};
				putRequest();
			}, (error) => {
				reject(error);
			});
		});
	}

	patchData(url: string, data?: any, returnLocation?: boolean): Promise<any> {
		return new Promise((resolve, reject) => {
			this.checkConnection().then(() => {
				const patchRequest: VoidFunction = () => {
					this.httpHelper.patch(url, data || { }, this.getRequestOptions(url)).then((resp) => {
						this.header = null;
						if (returnLocation) {
							resolve({
								location: UtilHelper.isNativeApp() ? resp.headers['location'] : resp.headers.get('Location'),
								data: this.getJsonData(resp && resp.body || resp)
							});
						} else {
							resolve(this.getJsonData(resp && resp.body || resp));
						}
					}, (error) => {
						this.catchError(error, url, patchRequest, reject, data);
					}).catch((error) => {
						this.catchError(error, url, patchRequest, reject, data);
					});
				};
				patchRequest();
			}, (error) => {
				reject(error);
			});
		});
	}

	postData(url: string, data?: any, returnLocation?: boolean): Promise<any> {
		return new Promise((resolve, reject) => {
			this.checkConnection().then(() => {
				const postRequest: VoidFunction = () => {
					this.httpHelper.post(url, data || { }, this.getRequestOptions(url)).then((resp) => {
						this.header = null;
						if (returnLocation) {
							resolve({
								location: UtilHelper.isNativeApp() ? resp.headers['location'] : resp.headers.get('Location'),
								data: this.getJsonData(resp.body || resp)
							});
						} else {
							resolve(this.getJsonData(resp.body || resp));
						}
					}, (error) => {
						this.catchError(error, url, postRequest, reject, data);
					}).catch((error) => {
						this.catchError(error, url, postRequest, reject, data);
					});
				};
				postRequest();
			}, (error) => {
				reject(error);
			});
		});
	}

	deleteData(url: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.checkConnection().then(() => {
				const deleteRequest: VoidFunction = () => {
					this.httpHelper.delete(url, this.getRequestOptions(url)).then((resp) => {
						this.header = null;
						resolve(this.getJsonData(resp));
					}, (error) => {
						this.catchError(error, url, deleteRequest, reject);
					}).catch((error) => {
						this.catchError(error, url, deleteRequest, reject);
					});
				};
				deleteRequest();
			}, (error) => {
				reject(error);
			});
		});
	}

	buildUrlPathParams(params: any, urlRequest: string): string {
		if (params == null)
			return null;

		for (const key in params) {
			if (params[key] != null) {
				urlRequest = urlRequest.replace("{" + key + "}", encodeURIComponent(params[key]));
			}
		}
		console.log("urlRequest: " + urlRequest);
		return urlRequest;
	}

	buildQueryParams(params: any): string {
		if (params == null)
			return null;

		const urlParams: URLSearchParams = new URLSearchParams();
		for (const key in params) {
			if (params[key] != null) {
				urlParams.append(key, params[key]);
			}
		}
		return urlParams.toString();
	}

	/**
	 * Adiciona properties no header da requisição. Passar os campos chave-valor.
	 * Exemplo: key = 'Content-Type'; value = 'application/json'
	 */
	addParamsHeader(key: string, value: string): void {
		if (!this.header) {
			this.header = { };
		}
		if (this.header[key]) {
			delete this.header[key];
		}
		this.header[key] = value;
	}

	/* Private métodos
	================================*/

	private catchError(error: any, url: string, retryCallback: VoidFunction, reject: any, data?: any): void {
		this.header = null;
		this.treatError(error, url, data).then((e: any) => {
			if (e.tryAgain) {
				retryCallback();
			} else {
				reject(e);
			}
		});
	}

	private checkConnection(): Promise<void> {
		console.log("Status rede: " + this.network.type);
		if (this.network.type === 'none') {
			return Promise.reject(new ErrorApp(
				APP_CONFIG.ERRORS.OFFLINE,
				"Você está offline."
			));
		}
		return Promise.resolve();
	}

	private getJsonData(response: any): any {
		let body: any = null;
		try {
			if (UtilHelper.isNativeApp()) {
				if (response.data != null) {
					body = JSON.parse(response.data);
				} else {
					body = typeof response === 'string' ? JSON.parse(response) : response;
				}
			} else {
				body = response;
			}
		} catch (e) {
			//sem content no response
		}
		return body;
	}

	private treatError(error: HttpErrorResponse | any, urlRequest: string, data?: any): Promise<ErrorApp> {
		return new Promise((resolve) => {
			console.error(JSON.stringify(error));
			const errorApi: ErrorApp = new ErrorApp(0, "ERRORS.GENERIC");

			if (error.status) {
				// 401 (Não autorizado - Usuario e ou senha inválido, token expirado)
				// 403 (Acesso negado ao acessar o recurso, scope inválido)
				switch (error.status) {
					case 500:
						errorApi.message = "ERRORS.SERVER_ERROR";
						this.sendLogError("ERRORS.SERVER_ERROR", urlRequest, data);
						break;

					case 503:
						errorApi.message = "ERRORS.SERVICE_UNAVAILABLE";
						break;

					case 504:
						errorApi.message = "ERRORS.TIMEOUT";
						break;

					case 403:
						errorApi.message = "ERRORS.ACCESS_DENIED";
						break;

					case 404:
						errorApi.message = "ERRORS.NOT_FOUND";
						break;

					default:
						break;
				}
				errorApi.status = error.status;
			}

			if (error && error.name && error.name === "TimeoutError") {
				errorApi.message = "ERRORS.TIMEOUT";
			} else {
				const body: any = this.getJsonData(error.error || error.data);

				if (body) {
					errorApi.fullError = body;
					if (body.errorMessage || body.message || body.errors) {
						errorApi.message = body.errorMessage || body.message || body.errors;
					}
				}
			}

			if (errorApi.status === 401 && !this.isLogin(urlRequest)) {
				this.refreshToken(errorApi).then((errorRefresh: ErrorApp) => {
					resolve(errorRefresh);
				});
			} else {
				resolve(errorApi);
			}
		});
	}

	private sendLogError(keyError: string, urlRequest: string, data?: any): void {
		try {
			const date: Date = new Date();
			const params: any = {
				date: date,
				url: urlRequest,
				data: data || null
			};
			this.analyticsService.gaEvent(keyError, urlRequest, JSON.stringify(params));
		} catch (error) {
			console.error(error);
		}
	}

	private async refreshToken(erroApp: ErrorApp): Promise<ErrorApp> {
		try {
			await this.userService.refreshToken();
			erroApp.tryAgain = true;
		} catch (error) {
			console.error(error);
			if (UtilHelper.isNativeApp() && this.userService.user && this.userService.user.loginProvider !== LoginProvider.OWN) {
				//Tento fazer silent login rede social
				try {
					await this.userService.trySilentLoginSocial();
					erroApp.tryAgain = true;
				} catch (error) {
					console.error(error);
					await this.userService.removeUser();
				}
			} else {
				await this.userService.removeUser();
			}
		} finally {
			return erroApp;
		}
	}

	/**
	 * Retorna o cabeçalho com os parametros setados para a requisição.
	 */
	private getRequestOptions(urlRequest: string): any {
		let options: any = null;
		if (!this.isLogin(urlRequest) && !this.isExternalRequest(urlRequest)) {
			this.addUserTokenToHeader();
		}
		if (this.isFastgetApi(urlRequest)) {
			this.addParamsHeader('X-Api-Key', APP_CONFIG.API_KEY);
		}
		if (this.header) {
			options = UtilHelper.isNativeApp() ? this.header : new HttpHeaders(this.header);
		}
		return options;
	}

	private addUserTokenToHeader(): void {
		if (this.token) {
			this.addParamsHeader('Authorization', 'Bearer ' + this.token);
		}
	}

	private isLogin(urlRequest: string): boolean {
		return urlRequest.indexOf(WEBSERVICE.AUTH_TOKEN) !== -1
			|| urlRequest.indexOf(WEBSERVICE.LOGIN_FACEBOOK) !== -1
			|| urlRequest.indexOf(WEBSERVICE.LOGIN_GOOGLE) !== -1
			|| urlRequest.indexOf(WEBSERVICE.LOGIN_APPLE) !== -1;
	}

	private isExternalRequest(urlRequest: string): boolean {
		if (!urlRequest) return false;
		return urlRequest.indexOf("https://viacep.com.br/ws/") !== -1
			|| urlRequest.indexOf("/public/") !== -1
			|| urlRequest.indexOf(WEBSERVICE.CREATE_PAGARME_CARD_TOKEN) !== -1;
	}

	private isFastgetApi(urlRequest: string): boolean {
		if (!urlRequest) return false;
		return urlRequest.indexOf("/proxy/") != -1 || urlRequest.indexOf("api.fastget.com.br/") != -1;;
	}

	// private ignoreRefreshToken(urlRequest: string): boolean {
	// 	return this.isLogin(urlRequest) || urlRequest.indexOf(WEBSERVICE.VALIDATE_USER_HASH) > -1;
	// }
}
