export enum AppEvents {
	LOGIN = "user:login",
	LOGOUT = "user:logout",
	USER_VALIDATE_PHONE = "login:userValidatePhone",
	EXIT_APP = "app:exit",
	AUTO_LOGIN = "login:autoLogin",

	LOCATION_UPDATE_STORES = "location:updateEstablishments",
	HOME_ENTER = "home:enter",
	TABS_ENTER = "tabs:enter",
	SELECT_TAB = "tabs:selectTab",
	RELOAD_MENU = "menu:reload",
	SHOW_MAINTENANCE = "app:showModalMaintenance",

	// Payment
	CARD_ADDED = "card:added",
	CARDS_RELOAD = "cards:reload",
	COUPON_ADDED = "coupon:added",
	DOCUMENT_ADDED = "document:added",
	DELIVERY_ADDRESS_ADDED = "deliveryAddress:added",
	CAR_DRIVEIN_ADDED = "carDriveIn:added",
	NEW_ADDRESS_ADDED = "newAddress:added",

	// Orders
	ORDER_LIST_GET_ORDER_DETAIL = "order-list:getOrderDetail",
	ORDER_LIST_RELOAD = "order-list:reload",
	ORDER_RELOAD = "order:reload",

	// Reservations
	RESERVATION_LIST_RELOAD = "reservation-list:reload",
	RESERVATION_RELOAD = "reservation:reload",
	CONTINUE_TO_USER_DATA_PAGE = "reservation:continueToUserData",
	FESTIVAL_ADDRESS_ADDED = "reservation:festivalAddressAdded",
	RESERVATION_LIST_GET_DETAIL = "reservation-list:getReservationDetail",

	// Queue
	QUEUE_RELOAD = "queue:reload",
}
