import { environment } from 'src/environments/environment';

export let END_POINT: string =  environment.api;
let PAGARME_API: string = "https://api.pagar.me/";

// local, precisa do proxy devido erro de CORS. TODO: pensar em outra solução
if (document.URL.indexOf("http://localhost:81") === 0) {
	END_POINT = environment.proxy;
	PAGARME_API = "/proxy/pagarme-api/";
} else {
	if (environment.production) {
		END_POINT = environment.api;
	} else {
		// Ambiente de homologação é possível alterar em tempo de execução
		const manuallyApiUrl: string = localStorage.getItem("apiUrl");
		if (manuallyApiUrl) {
			END_POINT = manuallyApiUrl + "/api/";
		} else {
			END_POINT = environment.api;
		}
	}
}
// let END_POINT: string =  environment.api; //(document.URL.indexOf("http://localhost:8100") === 0 ? environment.proxy : environment.api);

// tslint:disable-next-line: typedef
export const WEBSERVICE = {
	GET_PHOTOS: END_POINT + "v1/public/photos/",

	GET_STORE_BY_QRCODE: END_POINT + "v1/public/establishment-info?tableCode={tableCode}",
	GET_STORE_BY_ID: END_POINT + "v1/public/store-info/uuid/", // {uuid}
	GET_NEARBY_STORES: END_POINT + "v1/public/stores?lat={lat}&lon={lon}&radius={radius}&page={page}&size={size}",
	GET_CATEGORIES: END_POINT + "v1/public/categories/uuid?storeUuid={storeUuid}&withProducts=true&customer=true",
	GET_HIGHLIGHT_PRODUCTS: END_POINT + "v1/public/stores/{storeUuid}/products-highlight",

	GET_PLACE_BY_ID: END_POINT + "v1/public/place-info/" ,

	//Product
	GET_PRODUCTS: END_POINT + "v1/public/products?categoryId={categoryId}&status={status}",
	GET_ALL_PRODUCTS: END_POINT + "v1/public/products?establishmentId={establishmentId}&status={status}",
	GET_BUSINESS_HOURS: END_POINT + "v1/public/businesshours?establishmentId={establishmentId}",
	GET_PRODUCT_BY_ID: END_POINT + "v1/public/products/",
	GET_PRODUCT_HOURS: END_POINT + "v1/public/producthours?productId={productId}",
	GET_PRODUCT_COMPLEMENTS: END_POINT + "v1/public/complements?productId={productId}&withOptions=true&status=ACTIVE",
	VALIDATE_PRODUCT_AVAILABILITY: END_POINT + "v1/public/products/validatesProductAvailability",

	//Order
	SAVE_ORDER: END_POINT + "v3/protected/orders",
	GET_ORDERS: END_POINT + "v2/protected/orders?userId={userId}&size={size}&page={page}&orderByDtDesc=true",
	GET_ORDER: END_POINT + "v2/protected/orders?orderId={orderId}",
	CANCEL_ORDER: END_POINT + "v3/protected/orders/cancel",

	GET_STORE_DELIVERY_FEES: END_POINT + "v1/public/deliveryFees?storeId=",
	GET_PLACE_DELIVERY_FEES: END_POINT + "v1/public/deliveryFees?placeId=",

	//ORDER RESERVATION
	GET_ORDER_RESERVATION_CONFIG: END_POINT + "v1/public/orderreservationconfig",
	GET_ORDER_RESERVATION_AVAILABILITY: END_POINT + "v1/public/orderreservation/availability?storeId={storeId}&startDate={startDate}&endDate={endDate}&type={type}",
	GET_ORDER_RESERVATION_HOURS: END_POINT + "v1/public/orderreservation/hoursavailability?date={date}&storeId={storeId}&type={type}",
	START_PRE_SALE_ORDER: END_POINT + "v1/protected/orderreservation/{orderReservationId}/start-order",
	RESCHEDULE_ORDER: END_POINT + "v1/protected/orderreservation/reschedule",

	//Coupon
	GET_COUPONS: END_POINT + "v1/protected/coupons?userId={userId}&status={status}",
	VALIDATE_COUPON: END_POINT + "v1/protected/coupons/validate",

	//LOGIN
	LOGIN_FACEBOOK: END_POINT + "v1/public/facebook", //END_POINT_V1 + "public/facebook",
	LOGIN_GOOGLE: END_POINT + "v1/public/google",
	LOGIN_APPLE: END_POINT + "v1/public/apple",
	AUTH_TOKEN: END_POINT + "v1/oauth/token",
	VALIDATE_USER_HASH: END_POINT + "v1/public/userclient/validateUserHash",

	//SYSTEM
	API_VERSION_SYSTEM: END_POINT + "v1/public/apiversion",

	//USER_CLIENT
	CREATE_USER_CLIENT: END_POINT + "v1/public/userclient",
	USER_CLIENT: END_POINT + "v1/protected/userclient",
	VERIFY_UPDATE_PASSWORD_LINK: END_POINT + "v1/public/users/verifyUpdatePasswordLink?emailToken={token}",
	ACTIVE_ACCOUNT: END_POINT + "v1/public/users/activeAccount",
	FORGOT_PASSWORD: END_POINT + "v1/public/users/forgotPassword",
	CREATE_PASSWORD: END_POINT + "v1/public/users/createPassword",
	UPDATE_PASSWORD: END_POINT + "v1/public/users/updatePassword",
	UPDATE_PASSWORD_FROM_VIEW: END_POINT + "v1/protected/users/updatePassword",

	SEND_VALIDATION_CODE: END_POINT + "v1/protected/userclient/sendValidationCode?phoneNumber={phoneNumber}&userId={userId}&notificationType={notificationType}",
	VALIDATE_PHONE: END_POINT + "v1/protected/userclient/validatePhoneNumber?code={code}&userId={userId}",

	//Address
	GET_ADRESSES: END_POINT + "v1/protected/useraddress?userId={userId}",
	SAVE_ADDRESS: END_POINT + "v1/protected/useraddress",
	DELETE_ADDRESS: END_POINT + "v1/protected/useraddress/{addressId}",

	//Payments Iugu
	IUGU_GET_CARDS: END_POINT + "v1/protected/payment/{iuguCustomerId}",
	IUGU_GET_CARD_BY_ID: END_POINT + "v1/protected/payment/{iuguCustomerId}/{cardId}",
	IUGU_CREATE_CREDIT_CARD: END_POINT + "v1/protected/payment",
	IUGU_DELETE_CREDIT_CARD: END_POINT + "v1/protected/payment/delete/{iuguCustomerId}/{cardId}",
	// CONFIRM_PAYMENT_APP: END_POINT + "v1/protected/payment/app",
	
	//Payments Pagarme
	PAGARME_GET_CARDS: END_POINT + "v1/protected/users/{userId}/cards",
	PAGARME_GET_CARD_BY_ID: END_POINT + "v1/protected/users/{userId}/cards/{cardId}",
	PAGARME_CREATE_CREDIT_CARD: END_POINT + "v1/protected/users/{userId}/cards",
	PAGARME_DELETE_CREDIT_CARD: END_POINT + "v1/protected/users/{userId}/cards/{cardId}",
	CREATE_PAGARME_CARD_TOKEN: PAGARME_API + "core/v5/tokens?appId=",

	//Rating
	RATE: END_POINT + "v1/protected/rate",
	RATE_LIST: END_POINT + "v1/protected/rate/saveRateList",
	GET_ORDER_RATING: END_POINT + "v1/protected/rate/getRateByFilter?orderId={orderId}&getAnswers=true",
	GET_RATINGS: END_POINT + "v1/protected/rate/getRateByFilter?getAnswers=true&page={page}&size={size}&types={types}",

	// Reservations
	GET_STORE_RESERVATION_CONFIG: END_POINT + "v2/public/tablereservationconfig",
	GET_RESERVATION_AVAILABILITY: END_POINT + "v1/public/tablereservation/availability?storeId={storeId}&startDate={startDate}&endDate={endDate}",
	GET_RESERVATION_HOURS_AVAILABILITY: END_POINT + "v1/public/tablereservation/hoursavailability?date={date}&storeId={storeId}",
	GET_RESERVATIONS: END_POINT + "v1/protected/tablereservation?userId={userId}&reservationDate={reservationDate}&typeOfQuery={typeOfQuery}&status=APPROVED,WAITING_APPROVAL,WAITING_PAYMENT&orderByReservationDate={orderBy}",
	GET_CANCELED_RESERVATIONS: END_POINT + "v1/protected/tablereservation?userId={userId}&status=CANCELED_BY_STORE,CANCELED_BY_CUSTOMER&orderByReservationDate=desc",
	RESERVATION: END_POINT + "v1/protected/tablereservation", //{id}
	PUBLIC_RESERVATION: END_POINT + "v1/public/tablereservation",
	CANCEL_RESERVATION: END_POINT + "v1/protected/tablereservation/cancel",
	STELLA_PROMOTION: END_POINT + "v1/protected/tablereservation/stellapromotion",

	// Help
	SEND_ESTABLISHMENT_SUGGESTION: END_POINT + "v1/public/help/establishmentSuggestion",

	// QUEUE
	PUBLIC_QUEUE: END_POINT + "v1/public/queue",
	QUEUE: END_POINT + "v1/protected/queue",
	QUEUE_COUNT: END_POINT + "v1/public/queue/count",
	GET_QUEUE_CONFIG: END_POINT + "v1/public/queue-config",
	CANCEL_QUEUE: END_POINT + "v1/protected/queue/{uuid}/quit",
	PUBLIC_CANCEL_QUEUE: END_POINT + "v1/public/queue/{uuid}/quit",
	CHANGE_QUEUE_NOTES: END_POINT + "v1/protected/queue/{uuid}/customer-notes",
	PUBLIC_CHANGE_QUEUE_NOTES: END_POINT + "v1/public/queue/{uuid}/customer-notes",
};

// tslint:disable-next-line: typedef
export const APP_CONFIG = {
	VERSION: "2.24.1",
	API_KEY: "FASTGETPUBAPI",
	PAYMENT_PROVIDER: "PAGARME",
	WEBSITE: {
		SITE: "https://fastget.com.br",
		TERMS: "https://fastget.com.br/termos-consumidor-content.html",
		PRIVACY: "https://fastget.com.br/privacidade-content.html",
	},
	LANGUAGES: [{
		code: 'en',
		name: 'English'
	}, {
		code: 'pt',
		name: 'Portuguese'
	}],
	ERRORS: {
		TREATED: "TREATED",
		OFFLINE: "OFFLINE",
		FAIL_SILENT_LOGIN: "FAIL_SILENT_LOGIN",
		CANT_ADD_ITEM_TO_ORDER: "CANT_ADD_ITEM_TO_ORDER",
		CANT_ADD_ITEM_WITH_DIFFERENT_PICKUP_OPTION: "CANT_ADD_ITEM_WITH_DIFFERENT_PICKUP_OPTION",
		CANT_ADD_ITEM_DIFFERENT_STORE_TO_ORDER_RESERVATION: "CANT_ADD_ITEM_DIFFERENT_STORE_TO_ORDER_RESERVATION"
	},
	CURITIBA_CENTER_COORDS: {
		latitude: -25.4321587,
		longitude: -49.2796673
	}
};
