// tslint:disable-next-line: typedef
export const environment = {
	production: false,
	mode: 'STG',
	api: 'https://stg-api.fastget.com.br/api/',
	proxy: '/proxy/stg-service/',
	gaId: 'G-97J2BVEDSX',
	googleMapsKey: 'AIzaSyDOaqbPa1UV4-dTDeWX_pv7ZgJG7RwoQTg',
	push: {
		id: "c8ff856e-c6ba-4907-861f-f85d533a8ba2",
		google_project_number: "1096007169747"
	},
	festivalBomGormet: {
		uuid: "c2e7e4b1-b050-45d2-b9a5-d0107e3938a1"
	},
	firebaseConfig: {
		apiKey: "AIzaSyCY0MHUPLBxckPj1SKekVb8FthX8IQTucI",
		authDomain: "shop.fastget.com.br",
		databaseURL: "https://fastget.firebaseio.com",
		projectId: "fastget",
		storageBucket: "fastget.appspot.com",
		messagingSenderId: "1096007169747",
		appId: "1:1096007169747:web:a0ad46e366137bfe6ac626"
	},
	pagarmeKey: "pk_P5gBP9AHlVf89WlY"
};
