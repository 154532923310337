import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Market } from '@ionic-native/market/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, LoadingController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ImageLoaderConfigService } from 'ionic-image-loader';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';

import { APP_CONFIG } from './commom/constants';
import { AppEvents } from './enums/app-events.enum';
import { Language } from './enums/languages.enum';
import { PaymentProvider } from './enums/payment-provider.enum';
import { VersionCompare } from './enums/version-compare.enum';
import { PushHelper } from './helpers/push.helper';
import { UtilHelper } from './helpers/utils.helper';
import { VersionFirebase } from './interfaces/version-firebase.interface';
import { Notification } from './models/notification.model';
import { User } from './models/user.model';
import { MaintenancePage } from './pages/maintenance/maintenance.page';
import { AdsService } from './services/ads.service';
import { AnalyticsService } from './services/analytics.service';
import { EventService } from './services/event.service';
import { UserService } from './services/user.service';

declare var Iugu: any;
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	// rootPage: any;
	private registeredDeeplinks: boolean;

	private alreadyDisplayedAvailableUpdate: boolean;
	private pushRegistered: boolean;
	private oneSignalWeb: any;
	private pushHelper: PushHelper;

	constructor(
		private adsService: AdsService,
		private analyticsService: AnalyticsService,
		private appVersion: AppVersion,
		private alertCtrl: AlertController,
		private deeplinks: Deeplinks,
		private device: Device,
		private eventService: EventService,
		private imageLoaderConfig: ImageLoaderConfigService,
		private loadingCtrl: LoadingController,
		private market: Market,
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private ngZone: NgZone,
		private platform: Platform,
		private router: Router,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private swUpdate: SwUpdate,
		private toastController: ToastController,
		private translateService: TranslateService,
		private userService: UserService
	) {
		this.initializeApp();
	}

	initializeApp(): void {
		this.platform.ready().then(async () => {
			this.analyticsService.init();
			this.adsService.initThirky();

			await this.prepareEnvConfig();
			this.prepareLanguages();

			// const userToken: string = UtilHelper.getQueryParam('userToken');
			// const user: User = await this.userService.loadUser(false, userToken);
			const user: User = await this.userService.loadUser();
			this.initPushNotification(user);

			this.setupStatusbar();
			this.configApisEnvs();
			await this.userService.loadLocalConfig();

			this.setupImageLoader();
			this.listenAppEvents();
			this.checkAndroidWebviewVersion();
			this.checkVersion();

			if (UtilHelper.isNativeApp()) {
				this.splashScreen.hide();
			}
		});
	}

	private prepareLanguages(): void {
		this.translateService.addLangs(['pt', 'en', 'es']);
		console.log(this.translateService.getBrowserLang());
		let prefferedLanguage = localStorage.getItem("prefferedLanguage");

		if (prefferedLanguage) {
			this.translateService.setDefaultLang(prefferedLanguage);
			this.translateService.use(prefferedLanguage);
		} else {
			let browserLang = this.translateService.getBrowserLang();
			switch (browserLang) {
				case Language.PT.toLowerCase():
				case Language.EN.toLowerCase():
				case Language.ES.toLowerCase():
					this.translateService.setDefaultLang(browserLang);
					this.translateService.use(browserLang);
					break;

				default:
					// default seta como inglês, língua universal
					this.translateService.setDefaultLang(Language.EN.toLowerCase());
					this.translateService.use(Language.EN.toLowerCase());
					break;
			}
		}
	}

	private async prepareEnvConfig(): Promise<void> {
		try {
			await this.userService.getEnvConfigs();
			if (this.userService.envConfig) {
				// SETUP CUSTOM COLOR
				if (this.userService.envConfig.primaryColor) {
					const style: CSSStyleDeclaration = document.documentElement.style;
					style.setProperty('--ion-color-primary', this.userService.envConfig.primaryColor);
					if (this.userService.envConfig.primaryContrastColor) {
						style.setProperty('--ion-color-primary-contrast', this.userService.envConfig.primaryContrastColor);
					}
				}
				if (this.userService.envConfig.gaId) {
					this.analyticsService.initCustomGa(this.userService.envConfig.gaId);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}

	private setupImageLoader(): void {
		this.imageLoaderConfig.enableDebugMode();
		this.imageLoaderConfig.enableSpinner(false);
		this.imageLoaderConfig.enableFallbackAsPlaceholder(true);
		this.imageLoaderConfig.setMaximumCacheAge(45 * 24 * 60 * 60 * 1000); // 45 days
	}

	onActivate(ev: any): void {
		console.log("onActivate");
		console.log(ev);
	}

	private async configApisEnvs(): Promise<void> {
		if (APP_CONFIG.PAYMENT_PROVIDER == PaymentProvider.IUGU) {
			Iugu.setAccountID("5CCEE0C1523A408EAA3CD52FBFC04B7A");
			if (!environment.production) {
				Iugu.setTestMode(true);
			}
		}
	}

	private setupStatusbar(): void {
		try {
			if (UtilHelper.isNativeApp()) {
				if (this.platform.is("android")) {
					this.statusBar.backgroundColorByHexString("#00000000");

					const style: CSSStyleDeclaration = document.documentElement.style;
					if ((window as any).AndroidNotch) {
						(window as any).AndroidNotch.getInsetTop((px: number) => {
							console.log(px);
							if (px && px > 0) {
								style.setProperty('--ion-safe-area-top', px + 'px');
							} else {
								style.setProperty('--ion-safe-area-top', '24px');
							}
						}, (err) => {
							console.error('Failed to get insets top:', err);
						});
					} else {
						style.setProperty('--ion-safe-area-top', '24px');
					}
				}
				this.statusBar.styleDefault();
			}
		} catch (error) {
			console.error(error);
		}
	}

	private configureDeeplinks(): void {
		this.registeredDeeplinks = true;
		this.deeplinks.route({ }).subscribe((match) => {
			console.log(match);
			// if (match.$link.fragment && match.$link.fragment.indexOf("/store/") > -1) {
			if (match.$link.url && (match.$link.url.indexOf("/store/") > -1 || match.$link.url.indexOf("/local/") > -1 || match.$link.url.indexOf("tableCode=") > -1) || match.$link.url.indexOf("/table/") > -1) {
				this.ngZone.run(async () => {
					const loading: HTMLIonLoadingElement = await this.loadingCtrl.create();
					await loading.present();
					setTimeout(() => {
						try {
							if (match.$link.url.indexOf("tableCode=") > -1 || match.$link.url.indexOf("/table/") > -1) {
								const tableKey: string = match.$link.url.indexOf("tableCode=") > -1 ? "tableCode=" : "/table/";
								const tableCodetSplited: Array<string> = match.$link.url.split(tableKey);
								console.log(tableCodetSplited);
								if (tableCodetSplited.length > 1 && tableCodetSplited[1]) {
									this.navCtrl.navigateForward(["/store", {
										tableCode: tableCodetSplited[1]
									}]);
									return;
								}
							} else {
								const key: string = match.$link.url.indexOf("/store/") > -1 ? "/store/" : "/local/";
								const keySplited: Array<string> = match.$link.url.split(key);
								console.log(keySplited);
								if (keySplited.length > 1) {
									// name/id
									const params: Array<string> = keySplited[1].split("/");
									if (params.length > 0) {
										const name: string = params[0];
										const uuid: string = params[1];
										console.log(uuid);
										if (uuid) {
											const page: string = key === "/store/" ? "/store" : "/local";
											this.navCtrl.navigateForward([page, {
												uuid: uuid,
												name: name,
												shouldPresentModalWelcome: false
											}]);
											return;
										}
									}
								}
							}
							this.showOKAlert("Link não reconhecido", "O link que você está tentando abrir não parece ser válido.");
						} catch (error) {
							console.error(error);
						} finally {
							loading.dismiss();
						}
					}, 1000);
				});
			}
			// else {
			// 	this.showOKAlert("Link não reconhecido", "O link que você está tentando abrir não parece válido para o app do Usuário consumidor.");
			// }
		}, (nomatch) => {
			//no match link
			console.log(nomatch);
		});
	}

	listenAppEvents(): void {
		this.eventService.subscribe(AppEvents.LOGIN, (user: User) => {
			console.log("login events");
			if (UtilHelper.isNativeApp()) {
				const env: string = environment.mode === "PROD" ? "PRD-" : "HOM-";
				window['plugins'].OneSignal.setExternalUserId(env + user.id);
			} else {
				this.initWebPushNotification(user);
			}
		});
		this.eventService.subscribe(AppEvents.LOGOUT, () => {
			if (UtilHelper.isNativeApp()) {
				window['plugins'].OneSignal.removeExternalUserId();
			} else {
				this.oneSignalWeb.removeExternalUserId();
				this.oneSignalWeb.setSubscription(false);
			}
		});
		this.eventService.subscribe(AppEvents.HOME_ENTER, () => {
			console.log("home:enter");
			if (!this.registeredDeeplinks && UtilHelper.isNativeApp()) {
				this.configureDeeplinks();
			}
		});
		this.eventService.subscribe(AppEvents.SHOW_MAINTENANCE, async () => {
			const modal: HTMLIonModalElement = await this.modalCtrl.create({
				component: MaintenancePage
			});
			modal.present();
		});
	}

	async showOKAlert(titleKey: string, messageKey?: string): Promise<void> {
		if (!titleKey || titleKey.length === 0) {
			titleKey = "UNKNOWN";
		}
		if (!messageKey || messageKey.length === 0) {
			messageKey = "UNKNOWN";
		}
		const alert: HTMLIonAlertElement = await this.alertCtrl.create({
			header: this.translateService.instant(titleKey),
			message: this.translateService.instant(messageKey),
			buttons: ["OK"]
		});
		alert.present();
	}

	/* PUSH
	-----------------------------------------------------------------*/

	initPushNotification(user?: User): void {
		this.pushHelper = new PushHelper(
			this.eventService,
			this.navCtrl,
			this.router,
			this.toastController
		);
		if (UtilHelper.isNativeApp()) {
			if (!this.device.isVirtual) {
				this.initNativePushNotification(user);
			}
		} else {
			this.initWebPushNotification(user);
		}
	}

	initNativePushNotification(user?: User): void {
		window['plugins'].OneSignal
			.startInit(environment.push.id, environment.push.google_project_number)
			.inFocusDisplaying(window['plugins'].OneSignal.OSInFocusDisplayOption.Notification)
			.iOSSettings({
				kOSSettingsKeyAutoPrompt: false,
				kOSSettingsKeyInAppLaunchURL: false
			})
			.handleNotificationReceived((notification) => {
				this.ngZone.run(() => {
					console.log("notification received", notification);
					this.pushHelper.handlePush(new Notification(notification, true));
				});
			})
			.handleNotificationOpened((push) => {
				this.ngZone.run(() => {
					console.log("notification opened", push);
					this.pushHelper.handlePush(new Notification(push.notification, true), true);
				});
			})
			.endInit();

		if (user && user.id) {
			const env: string = environment.mode === "PROD" ? "PRD-" : "HOM-";
			window['plugins'].OneSignal.setExternalUserId(env + user.id);
		}
	}

	private initWebPushNotification(user?: User): void {
		//  && location.hostname !== "localhost"
		if (user) {
			if (!this.pushRegistered || !this.oneSignalWeb) {
				this.oneSignalWeb = window['OneSignal'] || [];
				this.oneSignalWeb.push(() => {
					this.oneSignalWeb.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' };
					this.oneSignalWeb.SERVICE_WORKER_PATH = 'push/onesignal/OneSignalSDKWorker.js';
					this.oneSignalWeb.SERVICE_WORKER_UPDATER_PATH = 'push/onesignal/OneSignalSDKUpdaterWorker.js';
					this.oneSignalWeb.init({
						appId: environment.push.id,
						autoRegister: false,
						autoResubscribe: true,
						allowLocalhostAsSecureOrigin: true,
						welcomeNotification: {
							disable: true
						},
						persistNotification: false,
						// notificationClickHandlerMatch: 'origin',
						notificationClickHandlerAction: 'focus',
						promptOptions: {
							/* Change bold title, limited to 30 characters */
							siteName: 'Fastget',
							/* Subtitle, limited to 90 characters */
							actionMessage: "Enviamos notificações para você acompanhar seus pedidos, receber promoções e novidades.",
							/* Accept button text, limited to 15 characters */
							acceptButtonText: "Permitir",
							/* Cancel button text, limited to 15 characters */
							cancelButtonText: "Agora não"
						}
					});
				});
				this.oneSignalWeb.showHttpPrompt(); //{force: true}
				this.pushRegistered = true;
			}
			this.oneSignalWeb.on('subscriptionChange', (isSubscribed) => {
				console.log("The user's subscription state is now:", isSubscribed);
			});
			this.oneSignalWeb.on('notificationDisplay', (push: any) => {
				try {
					console.log('OneSignal notification displayed:', push);
					this.pushHelper.handlePush(new Notification(push, false));
				} catch (error) {
					console.error(error);
				}
			});
			const env: string = environment.mode === "PROD" ? "PRD-" : "HOM-";
			this.oneSignalWeb.setExternalUserId(env + user.id);
			this.oneSignalWeb.setSubscription(true);
		}
	}

	// CHECA VERSÃO
	// ------------------------------

	private async checkAndroidWebviewVersion(): Promise<void> {
		console.log(this.device.version);
		if (this.platform.is("cordova") && this.platform.is("android")) {
			try {
				// Se versão do Android é menor que Android 7, checo a versão do webview
				const versionCompare: VersionCompare = UtilHelper.compareVersions(this.device.version, "7.0");
				console.log(versionCompare);
				if (versionCompare == VersionCompare.REMOTE_VERSION_HIGHER) {
					window['plugins'].webViewChecker.getCurrentWebViewPackageInfo().then(async (packageInfo) => {
						console.log(packageInfo);

						if (packageInfo && packageInfo.versionName) {
							// Se versão do webview instalado for menor que versão 86, mostro aviso para atualizar
							const versionCompareWebview: VersionCompare = UtilHelper.compareVersions(packageInfo.versionName, "86.0");

							if (versionCompareWebview == VersionCompare.REMOTE_VERSION_HIGHER) {
								const alert: HTMLIonAlertElement = await this.alertCtrl.create({
									header: "Atualização necessária",
									message: "Para um melhor funcionamento do aplicativo, é necessário atualizar o sistema.",
									backdropDismiss: false,
									buttons: [{
										text: 'Mais tarde',
										role: 'cancel'
									}, {
										text: "Atualizar",
										handler: (data) => {
											window['plugins'].webViewChecker.openGooglePlayPage()
												.then(() => {
													navigator['app'].exitApp();
												})
												.catch((error) => { console.error(error); });
										}
									}]
								});
								await alert.present();
							}
						}
					}).catch((error) => {
						console.error(error);
					});
				}
			} catch (error) {
				console.error(error);
			}
		}
	}

	async checkVersion(): Promise<void> {
		if (UtilHelper.isNativeApp()) {
			const localVersion: string = await this.appVersion.getVersionNumber();
			console.log('versaoLocal: ', localVersion);
			try {
				// Fetch 1h em prod, e 10 minutos em homolog
				const fetchInterval: number = environment.mode === 'PROD' ? 3600 : 600;
				await (window as any).cordova.plugins.firebase.config.fetch(fetchInterval);
				await (window as any).cordova.plugins.firebase.config.activate();

				const keyFirebase: string = this.platform.is("android") ? "android_version" : "ios_version";
				const versionString: string = await (window as any).cordova.plugins.firebase.config.getString(keyFirebase);
				console.log(versionString);

				if (versionString) {
					const remoteVersion: VersionFirebase = JSON.parse(versionString);
					if (remoteVersion) {
						this.userService.localConfig.remoteVersion = remoteVersion;
						this.userService.updateLocalConfig();
						this.compareVersion(localVersion, remoteVersion);
					}
				}
			} catch (error) {
				console.error(error);

				// No iOS, não faz o fetch sempre, e as vezes ocasiona em erros. Por isso é salvo no Storage a última versão para comparar
				this.compareVersion(localVersion, this.userService.localConfig.remoteVersion);
			}
		} else {
			console.log("swUpdate isEnabled: " + this.swUpdate.isEnabled);
			if (this.swUpdate.isEnabled) {
				this.swUpdate.available.subscribe(async (event) => {
					console.log('current version is', event.current);
					console.log('available version is', event.available);

					if (!this.alreadyDisplayedAvailableUpdate) {
						const toast: HTMLIonToastElement = await this.toastController.create({
							header: 'Atualização disponível!',
							message: 'Para uma melhor experiência, recarregue a página para atualizar.',
							position: 'top',
							color: 'dark',
							cssClass: 'toast-with-buttons',
							buttons: [{
								icon: 'refresh-outline',
								text: 'Atualizar',
								cssClass: 'primary',
								handler: () => {
									this.updateApp();
								}
							}]
						});
						toast.present();
						this.alreadyDisplayedAvailableUpdate = true;
					}
				});
				// Primeira vez, já checa.. depois somente a cada 6 horas se nao atualizar a página
				this.swUpdate.checkForUpdate();
				interval(6 * 60 * 60 * 1000).subscribe(() => {
					console.log("checando se tem nova versão");
					this.swUpdate.checkForUpdate();
				});
			}
		}
	}

	private async compareVersion(localVersion: string, remoteVersion: VersionFirebase): Promise<void> {
		try {
			const result: VersionCompare = UtilHelper.compareVersions(localVersion, remoteVersion.currentVersion);
			switch (result) {
				case VersionCompare.REMOTE_VERSION_HIGHER:
					// Checa se a versão atual é obrigatória
					if (remoteVersion.isMandatory) {
						// TODO: Modal personalizada
						const buttons: Array<any> = [];
						if (this.platform.is('android')) {
							buttons.push({
								text: 'Mais tarde',
								role: 'cancel',
								handler: () => {
									// this.platform.exitApp();
									navigator['app'].exitApp();
								}
							});
						}
						buttons.push({
							text: 'Atualizar',
							handler: () => {
								this.updateApp();
								return false;
							}
						});
						const alert: HTMLIonAlertElement = await this.alertCtrl.create({
							header: 'Atualização',
							message: "Existe uma nova atualização no aplicativo Fastget. Atualize para continuar utilizando.",
							backdropDismiss: false,
							buttons: buttons
						});
						alert.present();
					} else {
						const toast: HTMLIonToastElement = await this.toastController.create({
							header: 'Atualização disponível!',
							message: 'Olá, existe uma nova versão do aplicativo Fastget',
							position: 'top',
							duration: 7000,
							color: 'dark',
							cssClass: 'toast-with-buttons',
							buttons: [{
								icon: 'refresh-outline',
								text: 'Atualizar',
								cssClass: 'primary',
								handler: () => {
									this.updateApp();
								}
							}]
						});
						toast.present();
					}
					break;

				case VersionCompare.LOCAL_VERSION_HIGHER:
					console.log("ATUALIZE A VERSÃO REMOTA NO FIREBASE, a versão atual remota " + remoteVersion.currentVersion + " é INFERIOR a versão local " + localVersion);
					break;

				case VersionCompare.IDENTICAL_VERSIONS:
					console.log("versão atual remota " + remoteVersion.currentVersion + " é igual a versão local " + localVersion);
					break;

				default:
					break;
			}
		} catch (error) {
			console.error(error);
		}
	}

	private async updateApp(): Promise<void> {
		const loading: HTMLIonLoadingElement = await this.loadingCtrl.create();
		try {
			await loading.present();
			if (UtilHelper.isNativeApp()) {
				const appId: string = this.platform.is("ios") ? "id1465662498" : "br.com.fastget.consumer.app";
				await this.market.open(appId);
			} else {
				this.swUpdate.activateUpdate()
					.then(() => document.location.reload())
					.catch(() => document.location.reload());
			}
		} catch (error) {
			const toast: HTMLIonToastElement = await this.toastController.create({
				message: "Houve um erro ao tentar abrir a loja de aplicativos",
				duration: 3500
			});
			toast.present();
		} finally {
			loading.dismiss();
		}
	}
}
